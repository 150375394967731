<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/accounts/${$route.params.id}`" exact exact-active-class="active">Account</b-nav-item>
            <b-nav-item v-if="root && account?.subtype === 'user'" :to="`/accounts/${$route.params.id}/tenants`" exact exact-active-class="active">Tenants</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/attributes`" exact exact-active-class="active">Attributes</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/factors`" exact exact-active-class="active">Factors</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/controls`" exact exact-active-class="active">Controls</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/tokens`" exact exact-active-class="active">Tokens</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
        <b-button class="ml-2" variant="primary" v-on:click="$emit('show', 'create-account-control', account.subtype === 'user' ? { account: $route.params.id, type: 'permission', label: 'Permission', types: [{ value: 'permission', text: 'Permission' }] } : { account: $route.params.id, types: [{ value: 'rule', text: 'Rule' }, { value: 'permission', text: 'Permission' }] })">Create</b-button>
    </b-card-header>
    <b-card-body v-if="consents && permissions && rules">
        <b-table id="table" outlined hover :items="consents.items.concat(permissions.items).concat(rules.items)" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/${item.type}s/${item.id}`)" class="mb-0" show-empty empty-text="This account has no controls." empty-filtered-text="No controls match your search. If more controls are available you can load them by clicking 'Load More' below.">
            <template #cell(status)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
            </template>
            <template #cell(actions)="data">
                <b-button v-if="['ENABLED'].includes(data.item.status)" variant="outline-danger" size="sm" v-on:click="$emit('disable', data.item.type, data.item.id)">Disable</b-button>
                <b-button v-else-if="['DISABLED'].includes(data.item.status)" variant="outline-success" size="sm" :disabled="data.item.type === 'consent'" v-on:click="$emit('enable', data.item.type, data.item.id)">Enable</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="listControls()">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="consents && permissions && rules" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? (consents.items.length + permissions.items.length + rules.items.length)) - page * 10, 0)">Next</b-button>
        <b-button v-if="consents?.nextToken || permissions?.nextToken || rules?.nextToken" variant="outline-primary" v-on:click="nextControls()" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="consents?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ consents.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'control',
        label: 'Control',
        sortable: true,
        formatter: 'label'
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        formatter: value => TYPES[value]
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const TYPES = {
    'consent': 'Consent',
    'permission': 'Permission',
    'rule': 'Rule'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'AccountControls',

    /**
     * EVENTS
     */
    emits: [ 'load', 'next', 'show', 'disable', 'enable' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        consents: Array,
        permissions: Array,
        rules: Array,
        account: Object,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.consents || !this.permissions || !this.rules) {
                    this.listControls();
                }
                if (!this.$store.state.controls) {
                    this.$emit('load', 'controls', true); // LOAD ALL
                } else if (this.$store.state.controls.nextToken) {
                    this.$emit('next', 'controls', this.$store.state.controls.nextToken, true); // LOAD ALL
                }
            }
        },

        /**
         * CONTROLS
         */
        async listControls() {
            this.$emit('load', 'consents');
            this.$emit('load', 'permissions');
            this.$emit('load', 'rules');
        },

        async nextControls() {
            this.$emit('next', 'consents');
            this.$emit('next', 'permissions');
            this.$emit('next', 'rules');
        },

        /**
         * UTILITIES
         */
        label(value) {
            return this.$store.state.controls?.items.find(control => control.id === value)?.label;
        }
    }
}
</script>