<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/events.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Event</h3>
            </b-card-header>
            <b-card-body v-if="event">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="event.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Time" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="event.time.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Type" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="event.type" :options="types" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Origin" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="event.origin" readonly></b-form-input>
                                <b-input-group-append>
                                    <b-button v-if="event.type === 'API' && isURL(event.origin)" :href="event.origin" target="_blank" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('tenant') && !event.action.startsWith('delete')" to="/tenant" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('account') && !event.action.startsWith('delete')" :to="`/accounts/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('factor') && !event.action.startsWith('delete')" :to="`/factors/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('control') && !event.action.startsWith('delete')" :to="`/controls/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('extension') && !event.action.startsWith('delete')" :to="`/extensions/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('enrollment') && !event.action.startsWith('delete')" :to="`/enrollments/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('consent') && !event.action.startsWith('delete')" :to="`/consents/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('permission') && !event.action.startsWith('delete')" :to="`/permissions/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('rule') && !event.action.startsWith('delete')" :to="`/rules/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('token') && !event.action.startsWith('delete')" :to="`/tokens/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('attribute') && !event.action.startsWith('delete')" :to="`/attributes/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('claim') && !event.action.startsWith('delete')" :to="`/claims/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATABASE' && event.action.endsWith('source') && !event.action.startsWith('delete')" :to="`/sources/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'AUTHENTICATION'" :to="`/factors/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'AUTHORIZATION'" :to="`/accounts/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATA' && event.action.startsWith('source')" :to="`/sources/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'DATA' && event.action.startsWith('capture')" :to="`/factors/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'COMMUNICATION' && event.action.endsWith('otp')" :to="`/factors/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'COMMUNICATION' && event.action.endsWith('account')" :to="`/accounts/${event.origin}`" variant="outline-primary">Open</b-button>
                                    <b-button v-else-if="event.type === 'COMMUNICATION' && event.action.endsWith('tenant')" to="/tenant" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="event.account_id">
                        <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="event.account_id" readonly></b-form-input>
                                <b-badge v-if="event.account_id === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${event.account_id}`" variant="outline-primary">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Action" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="event.action" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Result" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="event.result" :options="results" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="event.reason">
                        <b-form-group label="Reason" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="event.reason" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="event?.detail && event.detail !== '{}'">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'detail')">
                        <b-img src="/img/icons/detail.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Detail</h5>
                    </b-card-header>
                    <b-collapse id="detail" visible>
                        <b-card-body>
                            <b-row>
                                <b-col xl="12">
                                    <b-form-group>
                                        <b-form-textarea :value="JSON.parse(event.detail)" rows="10" readonly></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer class="text-muted bg-light" v-if="event?.action.startsWith('update')">
                            <small>To understand the format please see the <b-link href="https://www.npmjs.com/package/json-diff" target="_blank">json-diff</b-link> documentation.</small>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-footer class="text-muted bg-light">
                <small>Powered by <a href="https://documenter.getpostman.com/view/18129777/UVXgMHEv#4304e3f2-3499-4444-a79b-4543bdcd8639" target="_blank">Management API</a></small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const RESULTS = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'SUCCESS', text: 'Success' },
    { value: 'FAILED', text: 'Failed' }
];
const TYPES = [
    { value: 'API', text: 'API' },
    { value: 'DATA', text: 'Data' },
    { value: 'DATABASE', text: 'Database' },
    { value: 'COMMUNICATION', text: 'Communication' },
    { value: 'AUTHENTICATION', text: 'Authentication' },
    { value: 'AUTHORIZATION', text: 'Authorization' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Event',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // TOKEN
            token: undefined,
            // RESULTS
            results: RESULTS,
            // TYPES
            types: TYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.event) {
                this.getEvent();
            }
        },

        /**
         * EVENT
         */
        async getEvent() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getEvent($id: ID!) {
                                getEvent(id: $id) {
                                    id
                                    time
                                    type
                                    origin
                                    action
                                    result
                                    reason
                                    detail
                                    account_id
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.event = (await response.json()).data.getEvent;
                    // SET DATES
                    this.event.time = this.event.time ? new Date(this.event.time) : undefined;
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain event.', 'Event', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain event.', 'Event', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * URL
         */
        isURL(url) {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        }
    }
}
</script>