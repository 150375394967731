/**
 * IMPORTS
 */
import { createApp, configureCompat } from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createStore } from 'vuex';

import App from './App.vue';
import Dashboard from './components/Dashboard.vue';
import Tenant from './components/Tenant.vue';
import Tenants from './components/root/Tenants.vue';
import Users from './components/accounts/Users.vue';
import Clients from './components/accounts/Clients.vue';
import AccountCard from './components/AccountCard.vue';
import Account from './components/account/Account.vue';
import Claims from './components/account/Claims.vue';
import Enrollments from './components/account/Enrollments.vue';
import EnrollmentCard from './components/account/EnrollmentCard.vue';
import Enrollment from './components/account/enrollment/Enrollment.vue';
import EnrollmentLinks from './components/account/enrollment/EnrollmentLinks.vue';
import EnrollmentEvents from './components/account/enrollment/EnrollmentEvents.vue';
import AccountControls from './components/account/AccountControls.vue';
import Permission from './components/account/Permission.vue';
import Consent from './components/account/Consent.vue';
import Rule from './components/account/Rule.vue';
import Link from './components/account/Link.vue';
import AccountTenants from './components/account/root/AccountTenants.vue';
import AccountTenant from './components/account/root/AccountTenant.vue';
import AccountTokens from './components/account/AccountTokens.vue';
import AccountEvents from './components/account/AccountEvents.vue';
import Attributes from './components/Attributes.vue';
import AttributeCard from './components/AttributeCard.vue';
import Attribute from './components/attribute/Attribute.vue';
import AttributeSources from './components/attribute/AttributeSources.vue';
import AttributeClaims from './components/attribute/AttributeClaims.vue';
import ClaimCard from './components/account/ClaimCard.vue';
import Claim from './components/account/claim/Claim.vue';
import ClaimLinks from './components/account/claim/ClaimLinks.vue';
import Factors from './components/Factors.vue';
import FactorCard from './components/FactorCard.vue';
import Factor from './components/factor/Factor.vue';
import FactorSources from './components/factor/FactorSources.vue';
import FactorEvents from './components/factor/FactorEvents.vue';
import Controls from './components/Controls.vue';
import ControlCard from './components/ControlCard.vue';
import Control from './components/control/Control.vue';
import ControlConsents from './components/control/ControlConsents.vue';
import ControlPermissions from './components/control/ControlPermissions.vue';
import SourceCard from './components/SourceCard.vue';
import Source from './components/source/Source.vue';
import SourceEvents from './components/source/SourceEvents.vue';
import Tokens from './components/Tokens.vue';
import Token from './components/Token.vue';
import Events from './components/Events.vue';
import Event from './components/Event.vue';
import Extensions from './components/Extensions.vue';
import Extension from './components/Extension.vue';

import './assets/scss/custom.scss';

/**
 * CONFIGURATION
 */
configureCompat({ MODE: 2 });
const APP = createApp(App);
APP.use(BootstrapVue, {
    breakpoints: [ 'xs', 'sm', 'md', 'lg', 'xl', 'xxl' ]
});

/**
 * STORE
 */
const STORE = createStore({
    state: {
        tenant_id: undefined,
        domain: undefined,
        session: undefined,
        account_id: undefined,
        tenant: undefined,
        users: undefined,
        clients: undefined,
        factors: undefined,
        controls: undefined,
        events: undefined,
        extensions: undefined,
        tokens: undefined,
        attributes: undefined,
        tenants: undefined
    },
    mutations: {
        tenant_id(state, tenant_id) {
            state.tenant_id = tenant_id;
        },
        domain(state, domain) {
            state.domain = domain;
        },
        session(state, session) {
            state.session = session;
        },
        account_id(state, account_id) {
            state.account_id = account_id;
        },
        tenant(state, tenant) {
            state.tenant = tenant;
        },
        users(state, users) {
            state.users = users;
        },
        push_user(state, user) {
            state.users.items.push(user);
        },
        set_users_token(state, token) {
            state.users.nextToken = token;
        },
        clients(state, clients) {
            state.clients = clients;
        },
        push_user(state, client) {
            state.clients.items.push(client);
        },
        set_clients_token(state, token) {
            state.clients.nextToken = token;
        },
        factors(state, factors) {
            state.factors = factors;
        },
        push_factor(state, factor) {
            state.factors.items.push(factor);
        },
        set_factors_token(state, token) {
            state.factors.nextToken = token;
        },
        controls(state, controls) {
            state.controls = controls;
        },
        push_control(state, control) {
            state.controls.items.push(control);
        },
        set_controls_token(state, token) {
            state.controls.nextToken = token;
        },
        events(state, events) {
            state.events = events;
        },
        push_event(state, event) {
            state.events.items.push(event);
        },
        set_events_token(state, token) {
            state.events.nextToken = token;
        },
        extensions(state, extensions) {
            state.extensions = extensions;
        },
        push_extension(state, extension) {
            state.extensions.items.push(extension);
        },
        set_extensions_token(state, token) {
            state.extensions.nextToken = token;
        },
        tokens(state, tokens) {
            state.tokens = tokens;
        },
        push_token(state, token) {
            state.tokens.items.push(token);
        },
        set_tokens_token(state, token) {
            state.tokens.nextToken = token;
        },
        attributes(state, attributes) {
            state.attributes = attributes;
        },
        push_attribute(state, attribute) {
            state.attributes.items.push(attribute);
        },
        set_attributes_token(state, token) {
            state.attributes.nextToken = token;
        },
        tenants(state, tenants) {
            state.tenants = tenants;
        },
        push_tenant(state, tenant) {
            state.tenants.items.push(tenant);
        },
        set_tenants_token(state, token) {
            state.tenants.nextToken = token;
        }
    }
});
APP.use(STORE);

/**
 * ROUTER
 */
const ROUTER = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: Dashboard },
        { path: '/tenant', component: Tenant },
        { path: '/tenants', component: Tenants },
        { path: '/tenants/:id', component: AccountTenant },
        { path: '/accounts/users', component: Users },
        { path: '/accounts/clients', component: Clients },
        { path: '/accounts/:id', component: AccountCard, children: [
            { path: '', component: Account },
            { path: 'tenants', component: AccountTenants },
            { path: 'attributes', component: Claims },
            { path: 'factors', component: Enrollments },
            { path: 'controls', component: AccountControls },
            { path: 'tokens', component: AccountTokens },
            { path: 'events', component: AccountEvents }
        ]},
        { path: '/attributes', component: Attributes },
        { path: '/attributes/:id', component: AttributeCard, children: [
            { path: '', component: Attribute },
            { path: 'sources', component: AttributeSources },
            { path: 'claims', component: AttributeClaims }
        ]},
        { path: '/sources/:id', component: SourceCard, children: [
            { path: '', component: Source },
            { path: 'events', component: SourceEvents }
        ]},
        { path: '/links/:id', component: Link },
        { path: '/claims/:id', component: ClaimCard, children: [
            { path: '', component: Claim },
            { path: 'factors', component: ClaimLinks }
        ]},
        { path: '/enrollments/:id', component: EnrollmentCard, children: [
            { path: '', component: Enrollment },
            { path: 'attributes', component: EnrollmentLinks },
            { path: 'events', component: EnrollmentEvents }
        ]},
        { path: '/permissions/:id', component: Permission },
        { path: '/consents/:id', component: Consent },
        { path: '/rules/:id', component: Rule },
        { path: '/factors', component: Factors },
        { path: '/factors/:id', component: FactorCard, children: [
            { path: '', component: Factor },
            { path: 'attributes', component: FactorSources },
            { path: 'events', component: FactorEvents }
        ]},
        { path: '/controls', component: Controls },
        { path: '/controls/:id', component: ControlCard, children: [
            { path: '', component: Control },
            { path: 'consents', component: ControlConsents },
            { path: 'permissions', component: ControlPermissions }
        ]},
        { path: '/tokens', component: Tokens },
        { path: '/tokens/:id', component: Token },
        { path: '/events', component: Events },
        { path: '/events/:id', component: Event },
        { path: '/extensions', component: Extensions },
        { path: '/extensions/:id', component: Extension }
    ]
});
APP.use(ROUTER);

/**
 * APPLICATION
 */
APP.mount('#app');
