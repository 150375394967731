<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/tokens.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Tokens</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Tokens are currently only tracked when disabled.</small>
            </b-card-header>
            <b-card-body v-if="$store.state.tokens">
                <b-table id="table" outlined hover :items="$store.state.tokens.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/tokens/${item.id}`)" class="mb-0" show-empty empty-text="You have no tokens." empty-filtered-text="No tokens match your search. If more tokens are available you can load them by clicking 'Load More' below.">
                    <template #cell(account)="data">
                        <b-link :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                    </template>
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="listTokens()">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.tokens" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? $store.state.tokens.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.tokens?.nextToken" variant="outline-primary" v-on:click="nextTokens()" class="ml-2" :disabled="loading_more">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.tokens?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.tokens.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'subtype',
        label: 'Subtype',
        sortable: true,
        formatter: value => SUBTYPES[value]
    },
    {
        key: 'account',
        label: 'Account',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'expires_at',
        label: 'Expires At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    }
];
const STATUSES = {
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const SUBTYPES = {
    'oauth2:code': 'Authorization Code',
    'oauth2:access': 'Access Token',
    'oauth2:refresh': 'Refresh Token',
    'oauth2:assertion': 'Assertion',
    'oauth2:request': 'Authorization Request',
    'oidc1:id': 'Identity Token',
    'session': 'Session',
    'online:consent': 'Consent'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Tokens',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined,
            // LOADING (MORE)
            loading_more: false
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.$store.state.tokens) {
                this.listTokens();
            }
        },

        /**
         * TOKENS
         */
        async listTokens() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTokens($limit: Int) {
                                listTokens(limit: $limit) {
                                    items {
                                        id
                                        status
                                        subtype
                                        account
                                        created_at
                                        expires_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tokens = (await response.json()).data.listTokens;
                    // ADD REFRESH DATE
                    tokens.refreshed_at = new Date();
                    this.$store.commit('tokens', tokens);
                    // NOTIFY MORE AVAILABLE
                    if (tokens.nextToken) {
                        this.$emit('alert', 'More tokens are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Tokens', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tokens.', 'Tokens', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tokens.', 'Tokens', 'danger');
            }
            this.loading = undefined;
        },

        async nextTokens() {
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listTokens($limit: Int, $nextToken: String) {
                                listTokens(limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        status
                                        subtype
                                        account
                                        created_at
                                        expires_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "nextToken": "${this.$store.state.tokens.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const tokens = (await response.json()).data.listTokens;
                    // ADD NEW TOKENS
                    for (const token of tokens.items) {
                        this.$store.commit('push_token', token);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit('set_tokens_token', tokens.nextToken);
                    // NOTIFY MORE AVAILABLE
                    if (tokens.nextToken) {
                        this.$emit('alert', 'More tokens are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Tokens', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain tokens.', 'Tokens', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain tokens.', 'Tokens', 'danger');
            }
            this.loading_more = false;
        }
    }
}
</script>