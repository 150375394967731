<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/events.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Events</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button class="ml-2" variant="primary" v-on:click="$bvModal.show('set-filter')">Filter<b-badge class="ml-2" variant="warning">NEW</b-badge></b-button>
            </b-card-header>
            <b-card-header class="text-muted bg-light">
                <small>Events are currently processed every 15 minutes.</small>
            </b-card-header>
            <b-card-body v-if="$store.state.events">
                <b-table id="table" outlined hover :items="$store.state.events.items" :fields="fields" primary-key="id" sort-by="time" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => item._showDetails = !item._showDetails" class="mb-0" show-empty empty-text="No events match your filter. It can take up to 15 minutes for events to show up." empty-filtered-text="No events match your search. If more events are available you can load them by clicking 'Load More' below.">
                    <template #cell(origin)="data">
                        <b-link v-if="data.item.type === 'API' && isURL(data.value)" :href="data.value" target="_blank">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('tenant') && !data.item.action.startsWith('delete')" to="/tenant">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('account') && !data.item.action.startsWith('delete')" :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('factor') && !data.item.action.startsWith('delete')" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('control') && !data.item.action.startsWith('delete')" :to="`/controls/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('extension') && !data.item.action.startsWith('delete')" :to="`/extensions/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('enrollment') && !data.item.action.startsWith('delete')" :to="`/enrollments/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('consent') && !data.item.action.startsWith('delete')" :to="`/consents/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('permission') && !data.item.action.startsWith('delete')" :to="`/permissions/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('rule') && !data.item.action.startsWith('delete')" :to="`/rules/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('token') && !data.item.action.startsWith('delete')" :to="`/tokens/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('attribute') && !data.item.action.startsWith('delete')" :to="`/attributes/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('claim') && !data.item.action.startsWith('delete')" :to="`/claims/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATABASE' && data.item.action.endsWith('source') && !data.item.action.startsWith('delete')" :to="`/sources/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'AUTHENTICATION' && data.item.action.endsWith('factor')" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'AUTHENTICATION' && data.item.action.endsWith('enrollment')" :to="`/enrollments/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'AUTHORIZATION'" :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATA' && data.item.action.startsWith('source')" :to="`/sources/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'DATA' && data.item.action.startsWith('capture')" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'COMMUNICATION' && data.item.action.endsWith('otp')" :to="`/factors/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'COMMUNICATION' && data.item.action.endsWith('account')" :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                        <b-link v-else-if="data.item.type === 'COMMUNICATION' && data.item.action.endsWith('tenant')" to="/tenant">{{ data.value }}</b-link>
                        <span v-else>{{ data.value }}</span>
                    </template>
                    <template #cell(account_id)="data">
                        <b-link :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
                    </template>
                    <template #cell(result)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ results[data.value] }}</b-button>
                    </template>
                    <template #row-details="data">
                        <b-card class="shadow rounded bg-white" no-body fluid>
                            <b-card-body>
                                <b-row>
                                    <b-col xl="6" xxl="4">
                                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                                            <b-input-group>
                                                <b-form-input v-model="data.item.id" readonly></b-form-input>
                                                <b-input-group-append>
                                                    <b-button :to="`/events/${data.item.id}`" target="_blank" variant="outline-primary">Open</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" xxl="4" v-if="data.item.reason">
                                        <b-form-group label="Reason" label-align-sm="right" label-cols-sm="3">
                                            <b-form-input v-model="data.item.reason" readonly></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                            <b-card-body v-if="data.item.detail && data.item.detail !== '{}'">
                                <b-card class="shadow rounded bg-white" no-body fluid>
                                    <b-card-header class="bg-light d-flex">
                                        <b-img src="/img/icons/detail.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                        <h5 class="text-secondary mb-0 mr-2">Detail</h5>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="12">
                                                <b-form-group>
                                                    <b-form-textarea :value="JSON.parse(data.item.detail)" rows="10" readonly></b-form-textarea>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                    <b-card-footer class="text-muted bg-light" v-if="data.item.action.startsWith('update')">
                                        <small>To understand the format please see the <b-link href="https://www.npmjs.com/package/json-diff" target="_blank">json-diff</b-link> documentation.</small>
                                    </b-card-footer>
                                </b-card>
                            </b-card-body>
                        </b-card>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="listEvents()">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.events" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? $store.state.events.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.events?.nextToken" variant="outline-primary" v-on:click="nextEvents()" class="ml-2" :disabled="loading_more">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.events?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.events.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- SET FILTER -->
        <b-modal id="set-filter" title="Set Filter" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Date (Min)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.time_min_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_min_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.time_min_date">
                <b-col>
                    <b-form-group label="Time (Min)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.time_min_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_min_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Date (Max)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.time_max_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_max_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.time_max_date">
                <b-col>
                    <b-form-group label="Time (Max)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.time_max_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_max_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Type" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.type" :options="filter_types"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.type" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Origin" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.origin"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.origin" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Action" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.action"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.action" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Result" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.result" :options="filter_results"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.result" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col class="d-flex px-0">
                        <b-button variant="outline-secondary" v-on:click="$bvModal.hide('set-filter')">Cancel</b-button>
                        <b-button variant="success" class="ml-auto" v-on:click="updateFilter()">Update</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        formatter: value => TYPES[value]
    },
    {
        key: 'origin',
        label: 'Origin',
        sortable: true
    },
    {
        key: 'account_id',
        label: 'Account',
        sortable: true
    },
    {
        key: 'action',
        label: 'Action',
        sortable: true
    },
    {
        key: 'result',
        label: 'Result',
        sortable: true
    }
];
const RESULTS = {
    'PENDING': 'Pending',
    'SUCCESS': 'Success',
    'FAILED': 'Failed'
};
const TYPES = {
    'API': 'API',
    'DATA': 'Data',
    'DATABASE': 'Database',
    'COMMUNICATION': 'Communication',
    'AUTHENTICATION': 'Authentication',
    'AUTHORIZATION': 'Authorization'
};
const FILTER_TYPES = [
    { value: 'API', text: 'API' },
    { value: 'DATA', text: 'Data' },
    { value: 'DATABASE', text: 'Database' },
    { value: 'COMMUNICATION', text: 'Communication' },
    { value: 'AUTHENTICATION', text: 'Authentication' },
    { value: 'AUTHORIZATION', text: 'Authorization' },
];
const FILTER_RESULTS = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'SUCCESS', text: 'Success' },
    { value: 'FAILED', text: 'Failed' },
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Events',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // FIELDS
            fields: FIELDS,
            // RESULTS
            results: RESULTS,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined,
            // LOADING (MORE)
            loading_more: false,
            // FILTER (INPUT)
            filter_input: {},
            // FILTER (LIST)
            filter_list: {},
            // TYPES (FILTER)
            filter_types: FILTER_TYPES,
            // RESULTS (FILTER)
            filter_results: FILTER_RESULTS
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.$store.state.events) {
                this.updateFilter();
            }
        },

        /**
         * FILTER
         */
        updateFilter() {
            this.$bvModal.hide('set-filter');
            this.filter_list = {
                time: this.getTimeFilter(),
                type: this.filter_input.type ? { eq: this.filter_input.type } : undefined,
                origin: this.filter_input.origin ? { contains: this.filter_input.origin } : undefined,
                action: this.filter_input.action ? { contains: this.filter_input.action } : undefined,
                result: this.filter_input.result ? { eq: this.filter_input.result } : undefined
            };
            this.listEvents();
        },

        getTimeFilter() {
            if (this.filter_input.time_min_date) {
                if (this.filter_input.time_max_date) {
                    return { between: [ `${this.filter_input.time_min_date}T${this.filter_input.time_min_time || '00:00:00'}Z`, `${this.filter_input.time_max_date}T${this.filter_input.time_max_time || '24:00:00'}Z`] }
                } else {
                    return { ge: `${this.filter_input.time_min_date}T${this.filter_input.time_min_time || '00:00:00'}Z` };
                }
            } else if (this.filter_input.time_max_date) {
                return { le: `${this.filter_input.time_max_date}T${this.filter_input.time_max_time || '24:00:00'}Z` };
            } else {
                return undefined;
            }
        },

        /**
         * EVENTS
         */
        async listEvents() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($filter: TableEventFilterInput, $limit: Int) {
                                listEvents(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        time
                                        type
                                        origin
                                        action
                                        result
                                        reason
                                        detail
                                        account_id
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)}
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const events = (await response.json()).data.listEvents;
                    // ADD REFRESH DATE
                    events.refreshed_at = new Date();
                    this.$store.commit('events', events);
                    // NOTIFY MORE AVAILABLE
                    if (events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Events', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
            }
            this.loading = undefined;
        },

        async nextEvents() {
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($filter: TableEventFilterInput, $limit: Int, $nextToken: String) {
                                listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        time
                                        type
                                        origin
                                        action
                                        result
                                        reason
                                        detail
                                        account_id
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)},
                            "nextToken": "${this.$store.state.events.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const events = (await response.json()).data.listEvents;
                    // ADD NEW EVENTS
                    for (const event of events.items) {
                        this.$store.commit('push_event', event);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit('set_events_token', events.nextToken);
                    // NOTIFY MORE AVAILABLE
                    if (events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Events', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Events', 'danger');
            }
            this.loading_more = false;
        },

        /**
         * URL
         */
        isURL(url) {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        }
    }
}
</script>