<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/accounts.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Users</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button class="ml-2" variant="primary" v-on:click="$bvModal.show('set-filter')">Filter<b-badge class="ml-2" variant="warning">NEW</b-badge></b-button>
                <b-button class="ml-2" variant="primary" v-on:click="$emit('show', 'create-account', { subtype: 'user', label: 'Invited User', status: 'ENABLED', config: { internal: false } })">Create</b-button>
            </b-card-header>
            <b-card-body v-if="$store.state.users">
                <b-table id="table" outlined hover :items="$store.state.users.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/accounts/${item.id}`)" class="mb-0" show-empty empty-text="No users match your filter." empty-filtered-text="No users match your search. If more users are available you can load them by clicking 'Load More' below.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button variant="outline-danger" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status)" v-on:click="$emit('show', 'delete-account', data.item)">Delete</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="listUsers()">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.users" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max((rows ?? $store.state.users.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.users?.nextToken" variant="outline-primary" v-on:click="nextUsers()" class="ml-2" :disabled="loading_more">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.users?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.users.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

        <!-- SET FILTER -->
        <b-modal id="set-filter" title="Set Filter" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Creation Date (Min)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.created_at_min_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_min_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.created_at_min_date">
                <b-col>
                    <b-form-group label="Creation Time (Min)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.created_at_min_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_min_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Creation Date (Max)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.created_at_max_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_max_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.created_at_max_date">
                <b-col>
                    <b-form-group label="Creation Time (Max)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.created_at_max_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.created_at_max_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Expiration Date (Min)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.expires_at_min_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_min_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.expires_at_min_date">
                <b-col>
                    <b-form-group label="Expiration Time (Min)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.expires_at_min_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_min_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Expiration Date (Max)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.expires_at_max_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_max_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.expires_at_max_date">
                <b-col>
                    <b-form-group label="Expiration Time (Max)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.expires_at_max_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.expires_at_max_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.label"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.label" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Group" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.internal" :options="filter_boolean"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.internal" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.status" :options="filter_statuses"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.status" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col class="d-flex px-0">
                        <b-button variant="outline-secondary" v-on:click="$bvModal.hide('set-filter')">Cancel</b-button>
                        <b-button variant="success" class="ml-auto" v-on:click="updateFilter()">Update</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
 const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'expires_at',
        label: 'Expires At',
        sortable: true,
        formatter: value => value ? new Date(value).toLocaleString() : undefined
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const FILTER_STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const FILTER_BOOLEAN = [
    { value: true, text: 'Internal' },
    { value: false, text: 'External' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Users',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined,
            // LOADING (MORE)
            loading_more: false,
            // FILTER (INPUT)
            filter_input: {},
            // FILTER (LIST)
            filter_list: {
                subtype: {
                    eq: 'user'
                }
            },
            // STATUSES (FILTER)
            filter_statuses: FILTER_STATUSES,
            // BOOLEAN (FILTER)
            filter_boolean: FILTER_BOOLEAN
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.$store.state.users) {
                this.updateFilter();
            }
        },

        /**
         * FILTER
         */
        updateFilter() {
            this.$bvModal.hide('set-filter');
            this.filter_list = {
                created_at: this.getTimeFilter('created_at'),
                expires_at: this.getTimeFilter('expires_at'),
                label: this.filter_input.label ? { contains: this.filter_input.label } : undefined,
                internal: this.filter_input.internal !== undefined ? { eq: this.filter_input.internal } : undefined,
                status: this.filter_input.status ? { eq: this.filter_input.status } : undefined,
                subtype: { eq: 'user' } // FIXED
            };
            this.listUsers();
        },

        getTimeFilter(field) {
            if (this.filter_input[`${field}_min_date`]) {
                if (this.filter_input[`${field}_max_date`]) {
                    return { between: [ `${this.filter_input[`${field}_min_date`]}T${this.filter_input[`${field}_min_time`] || '00:00:00'}Z`, `${this.filter_input[`${field}_max_date`]}T${this.filter_input[`${field}_max_time`]|| '24:00:00'}Z`] }
                } else {
                    return { ge: `${this.filter_input[`${field}_min_date`]}T${this.filter_input[`${field}_min_time`] || '00:00:00'}Z` };
                }
            } else if (this.filter_input[`${field}_max_date`]) {
                return { le: `${this.filter_input[`${field}_max_date`]}T${this.filter_input[`${field}_max_time`] || '24:00:00'}Z` };
            } else {
                return undefined;
            }
        },

        /**
         * USERS
         */
        async listUsers() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listAccounts($filter: TableAccountFilterInput, $limit: Int) {
                                listAccounts(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        status
                                        created_at
                                        expires_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)}
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const users = (await response.json()).data.listAccounts;
                    // ADD REFRESH DATE
                    users.refreshed_at = new Date();
                    this.$store.commit('users', users);
                    // NOTIFY MORE AVAILABLE
                    if (users.nextToken) {
                        this.$emit('alert', 'More users are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Users', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain users.', 'Users', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain users.', 'Users', 'danger');
            }
            this.loading = undefined;
        },

        async nextUsers() {
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listAccounts($filter: TableAccountFilterInput, $limit: Int, $nextToken: String) {
                                listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        status
                                        created_at
                                        expires_at
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)},
                            "nextToken": "${this.$store.state.users.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const users = (await response.json()).data.listAccounts;
                    // ADD NEW USERS
                    for (const user of users.items) {
                        this.$store.commit('push_user', user);
                    }
                    // SET NEXT TOKEN
                    this.$store.commit('set_users_token', users.nextToken);
                    // NOTIFY MORE AVAILABLE
                    if (users.nextToken) {
                        this.$emit('alert', 'More users are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Users', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain users.', 'Users', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain users.', 'Users', 'danger');
            }
            this.loading_more = false;
        }
    }
}
</script>