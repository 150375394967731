<template>
    <b-overlay :show="loading || loading2">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading || loading2 }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/factors.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Factor</h3>
            </b-card-header>
            <RouterView v-slot="{ Component }">
                <component :is="Component" :filter="filter" :variant="variant" :factor="factor" :sources="sources" :events="events" @alert="sendAlert" @login="sendLogin" @loading="setLoading" @load="sendLoad" @next="sendNext" @show="sendShow" :loading_more="loading_more"/>
            </RouterView>
        </b-card>

        <!-- SET FILTER -->
        <b-modal id="set-filter" title="Set Filter" header-bg-variant="primary" header-text-variant="white" content-class="shadow" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Date (Min)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.time_min_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_min_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.time_min_date">
                <b-col>
                    <b-form-group label="Time (Min)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.time_min_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_min_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Date (Max)" label-align-sm="right" label-cols-sm="3" description="Please note date is provided in UTC.">
                        <b-input-group>
                            <b-form-datepicker v-model="filter_input.time_max_date" start-weekday="1"></b-form-datepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_max_date" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="filter_input.time_max_date">
                <b-col>
                    <b-form-group label="Time (Max)" label-align-sm="right" label-cols-sm="3" description="Please note time is provided in UTC.">
                        <b-input-group>
                            <b-form-timepicker v-model="filter_input.time_max_time" :hour12="false" show-seconds></b-form-timepicker>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.time_max_time" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Type" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.type" :options="filter_types"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.type" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Action" label-align-sm="right" label-cols-sm="3" description="Please note field is case sensitive.">
                        <b-input-group>
                            <b-form-input v-model="filter_input.action"></b-form-input>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.action" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Result" label-align-sm="right" label-cols-sm="3">
                        <b-input-group>
                            <b-form-select v-model="filter_input.result" :options="filter_results"></b-form-select>
                            <b-input-group-append>
                                <b-button v-on:click="delete filter_input.result" variant="outline-danger">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-row class="w-100">
                    <b-col class="d-flex px-0">
                        <b-button variant="outline-secondary" v-on:click="$bvModal.hide('set-filter')">Cancel</b-button>
                        <b-button variant="success" class="ml-auto" v-on:click="updateFilter()">Update</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FILTER_TYPES = [
    { value: 'DATA', text: 'Data' },
    { value: 'DATABASE', text: 'Database' },
    { value: 'COMMUNICATION', text: 'Communication' },
    { value: 'AUTHENTICATION', text: 'Authentication' }
];
const FILTER_RESULTS = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'SUCCESS', text: 'Success' },
    { value: 'FAILED', text: 'Failed' },
];

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'FactorCard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // SOURCES
            sources: undefined,
            // EVENTS
            events: undefined,
            // LOADING
            loading2: undefined,
            // FACTOR
            factor: undefined,
            // LOADING (MORE)
            loading_more: false,
            // FILTER (INPUT)
            filter_input: {},
            // FILTER (LIST)
            filter_list: {
                origin: {
                    eq: this.$route.params.id
                }
            },
            // TYPES (FILTER)
            filter_types: FILTER_TYPES,
            // RESULTS (FILTER)
            filter_results: FILTER_RESULTS
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.factor) {
                this.getFactor();
            }
        },

        /**
         * LOADING
         */
        setLoading(loading) {
            this.loading2 = loading;
        },

        /**
         * EVENTS
         */
        sendAlert(...args) {
            this.$emit('alert', ...args);
        },

        sendLogin(...args) {
            this.$emit('login', ...args);
        },

        sendLoad(...args) {
            if (args[0] === 'factor') {
                this.getFactor();
            } else if (args[0] === 'sources') {
                this.listSources();
            } else if (args[0] === 'events') {
                this.updateFilter();
            } else {
                this.$emit('load', ...args);
            }
        },

        sendNext(...args) {
            if (args[0] === 'events') {
                this.nextEvents();
            } else if (args[0] === 'sources') {
                this.nextSources();
            } else {
                this.$emit('next', ...args);
            }
        },

        sendShow(...args) {
            if (args[0] === 'set-filter') {
                this.$bvModal.show('set-filter');
            } else {
                this.$emit('show', ...args);
            }
        },

        /**
         * FILTER
         */
        updateFilter() {
            this.$bvModal.hide('set-filter');
            this.filter_list = {
                time: this.getTimeFilter(),
                type: this.filter_input.type ? { eq: this.filter_input.type } : undefined,
                action: this.filter_input.action ? { contains: this.filter_input.action } : undefined,
                result: this.filter_input.result ? { eq: this.filter_input.result } : undefined,
                origin: { eq: this.$route.params.id } // FIXED
            };
            this.listEvents();
        },

        getTimeFilter() {
            if (this.filter_input.time_min_date) {
                if (this.filter_input.time_max_date) {
                    return { between: [ `${this.filter_input.time_min_date}T${this.filter_input.time_min_time || '00:00:00'}Z`, `${this.filter_input.time_max_date}T${this.filter_input.time_max_time || '24:00:00'}Z`] }
                } else {
                    return { ge: `${this.filter_input.time_min_date}T${this.filter_input.time_min_time || '00:00:00'}Z` };
                }
            } else if (this.filter_input.time_max_date) {
                return { le: `${this.filter_input.time_max_date}T${this.filter_input.time_max_time || '24:00:00'}Z` };
            } else {
                return undefined;
            }
        },

        /**
         * FACTOR
         */
        async getFactor() {
            this.loading2 = 'Loading';
            try {

                // GET FACTOR
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getFactor($id: ID!) {
                                getFactor(id: $id) {
                                    id
                                    label
                                    score
                                    status
                                    subtype
                                    config {
                                        unique
                                        internal
                                        restricted
                                        case_sensitive
                                        require_validation_for_enablement
                                        max_attempts
                                        auto_unlock
                                        expires_in
                                        regex
                                        threshold
                                        token_regex
                                        otp
                                        issuer
                                        digits
                                        step
                                        window
                                        authorization_endpoint
                                        userinfo_endpoint
                                        token_endpoint
                                        jwks_uri
                                        response_mode
                                        response_type
                                        content_type
                                        claim
                                        scope
                                        nonce
                                        nonce_regex
                                        code_challenge_method
                                        code_challenge_regex
                                        signed_request
                                        client_authentication
                                        client_id
                                        client_secret
                                        client_keys {
                                            private
                                            public
                                        }
                                        capture_claims
                                        capture_tokens
                                        capture_input
                                        tokens {
                                            request {
                                                exp
                                                use
                                            }
                                            assertion {
                                                exp
                                                use
                                            }
                                        }
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    statistics {
                                        enrollments
                                        signups {
                                            pending
                                            success
                                            failed
                                        }
                                        logins {
                                            pending
                                            success
                                            failed
                                        }
                                        updated_at
                                    }
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.factor = (await response.json()).data.getFactor;
                    // SET DATES
                    this.factor.created_at = this.factor.created_at ? new Date(this.factor.created_at) : undefined;
                    this.factor.updated_at = this.factor.updated_at ? new Date(this.factor.updated_at) : undefined;
                    this.factor.expires_at = this.factor.expires_at ? new Date(this.factor.expires_at) : undefined;
                    this.factor.statistics.updated_at = this.factor.statistics?.updated_at ? new Date(this.factor.statistics.updated_at) : undefined;
                    // ADD REFRESH DATE
                    this.factor.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factor.', 'Factor', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factor.', 'Factor', 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * EVENTS
         */
        async listEvents() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($filter: TableEventFilterInput, $limit: Int) {
                                listEvents(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        time
                                        type
                                        action
                                        result
                                        reason
                                        detail
                                        account_id
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)}
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.events = (await response.json()).data.listEvents;
                    // ADD REFRESH DATE
                    this.events.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Factor', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Factor', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Factor', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextEvents() {
            if (!this.events.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listEvents($filter: TableEventFilterInput, $limit: Int, $nextToken: String) {
                                listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        time
                                        type
                                        action
                                        result
                                        reason
                                        detail
                                        account_id
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": ${JSON.stringify(this.filter_list)},
                            "nextToken": "${this.events.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const events = (await response.json()).data.listEvents;
                    // ADD NEW EVENTS
                    for (const event of events.items) {
                        this.events.items.push(event);
                    }
                    // SET NEXT TOKEN
                    this.events.nextToken = events.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (events.nextToken) {
                        this.$emit('alert', 'More events are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Factor', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain events.', 'Factor', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain events.', 'Factor', 'danger');
            }
            this.loading_more = undefined;
        },

        /**
         * SOURCES
         */
        async listSources() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listSources($filter: TableSourceFilterInput, $limit: Int) {
                                listSources(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        label
                                        claim
                                        attribute
                                        created_at
                                        status
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "factor": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.sources = (await response.json()).data.listSources;
                    // ADD REFRESH DATE
                    this.sources.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.sources.nextToken) {
                        this.$emit('alert', 'More attributes are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Factor', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attributes.', 'Factor', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attributes.', 'Factor', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextSources() {
            if (!this.sources.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listSources($filter: TableSourceFilterInput, $limit: Int, $nextToken: String) {
                                listSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        label
                                        claim
                                        attribute
                                        created_at
                                        status
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "factor": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.events.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const sources = (await response.json()).data.listSources;
                    // ADD NEW SOURCES
                    for (const source of sources.items) {
                        this.sources.items.push(source);
                    }
                    // SET NEXT TOKEN
                    this.sources.nextToken = sources.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (sources.nextToken) {
                        this.$emit('alert', 'More attributes are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Factor', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attributes.', 'Factor', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attributes.', 'Factor', 'danger');
            }
            this.loading_more = undefined;
        }
    }
}
</script>