<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/accounts/${$route.params.id}`" exact exact-active-class="active">Account</b-nav-item>
            <b-nav-item v-if="root && account?.subtype === 'user'" :to="`/accounts/${$route.params.id}/tenants`" exact exact-active-class="active">Tenants</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/attributes`" exact exact-active-class="active">Attributes</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/factors`" exact exact-active-class="active">Factors</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/controls`" exact exact-active-class="active">Controls</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/tokens`" exact exact-active-class="active">Tokens</b-nav-item>
            <b-nav-item :to="`/accounts/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
        <b-button v-if="account?.subtype === 'client'" class="ml-2" variant="primary" v-on:click="$emit('show', 'create-account-token', { id: account.id, method: account.config.authentication.method, scopes: rules?.items.filter(rule => rule.subtype === 'scope') })" :disabled="!account.config.grant_types.includes('client_credentials') || !rules || (account.status !== 'ENABLED')">Create</b-button>
    </b-card-header>
    <b-card-header class="text-muted bg-light">
        <small>Tokens are currently only tracked when disabled.</small>
    </b-card-header>
    <b-card-body v-if="tokens">
        <b-table id="table" outlined hover :items="tokens.items" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/tokens/${item.id}`)" class="mb-0" show-empty empty-text="This account has no tokens." empty-filtered-text="No tokens match your search. If more tokens are available you can load them by clicking 'Load More' below.">
            <template #cell(status)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'tokens')">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="tokens" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? tokens.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="tokens?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'tokens')" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="tokens?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ tokens.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'subtype',
        label: 'Subtype',
        sortable: true,
        formatter: value => SUBTYPES[value]
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'expires_at',
        label: 'Expires At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    }
];
const STATUSES = {
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const SUBTYPES = {
    'oauth2:code': 'Authorization Code',
    'oauth2:access': 'Access Token',
    'oauth2:refresh': 'Refresh Token',
    'oauth2:assertion': 'Assertion',
    'oauth2:request': 'Authorization Request',
    'oidc1:id': 'Identity Token',
    'session': 'Session',
    'online:consent': 'Consent'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'AccountTokens',

    /**
     * EVENTS
     */
     emits: [ 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        account: Object,
        filter: Function,
        variant: Function,
        loading_more: String,
        tokens: Array,
        rules: Array,
        root: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.tokens) {
                    this.$emit('load', 'tokens', false);
                }
                if (!this.rules) {
                    this.$emit('load', 'rules', true); // LOAD ALL
                } else if (this.rules.nextToken) {
                    this.$emit('next', 'rules', true); // LOAD ALL
                }
            }
        }
    }
}
</script>