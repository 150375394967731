<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/attributes/${$route.params.id}`" exact exact-active-class="active">Attribute</b-nav-item>
            <b-nav-item :to="`/attributes/${$route.params.id}/sources`" exact exact-active-class="active">Sources</b-nav-item>
            <b-nav-item :to="`/attributes/${$route.params.id}/claims`" exact exact-active-class="active">Search</b-nav-item>
        </b-nav>
    </b-card-header>
    <b-card-body v-if="attribute">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                    <b-form-input v-model="attribute.id" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label.">
                    <b-form-input v-model="attribute.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="attribute.subtype" :options="subtypes" disabled></b-form-select>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label-align-sm="right" label-cols-sm="3" description="This is the value (key) used for the attribute when passed in an identity token." :state="attribute.subtype.includes('oidc1') ? null : validField('value')" invalid-feedback="Please provide a valid value.">
                    <template #label>
                        Value<b-badge class="ml-2 text-white" variant="openid">OpenID Connect</b-badge>
                    </template>
                    <b-form-input v-model="attribute.value" :state="attribute.subtype.includes('oidc1') ? null : validField('value')" :readonly="attribute.subtype.includes('oidc1') || !canEdit()"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                    <b-form-select v-model="attribute.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Score" label-align-sm="right" label-cols-sm="3" description="This is the security score a user needs to create or view this attribute via the Authentication API." :state="validField('score')" invalid-feedback="Please provide a valid score. The minimum is 0.">
                    <b-form-input v-model="attribute.score" type="number" min="0" :state="validField('score')" :readonly="!canEdit()"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body v-if="attribute?.config">
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
            </b-card-header>
            <b-collapse id="configuration" visible>
                <b-card-body>
                    <b-row>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Internal" label-align-sm="right" label-cols-sm="3" description="This indicates whether this attribute is exclusive to internal accounts.">
                                <b-form-checkbox v-model="attribute.config.internal" switch :disabled="!canEdit()"></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Restricted" label-align-sm="right" label-cols-sm="3" description="This indicates whether this attribute is restricted to be created by administrators or the system only.">
                                <b-form-checkbox v-model="attribute.config.restricted" switch :disabled="!canEdit()"></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Require Validation" label-align-sm="right" label-cols-sm="3" description="This indicates whether the attribute must be validated before it gets enabled, i.e. whether users or admins must prove the attribute is legitimate.">
                                <b-form-checkbox v-model="attribute.config.require_validation_for_enablement" switch></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4" v-if="attribute.config.require_validation_for_enablement">
                            <b-form-group label="Expires In" label-align-sm="right" label-cols-sm="3" description="This is how long before a pending attribute expires, i.e. how long users or admins have to validate a pending attribute.">
                                <b-form-input v-model="attribute.config.expires_in" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4" v-if="attribute.config.regex !== null">
                            <b-form-group label="Regex" label-align-sm="right" label-cols-sm="3" description="This is the regular expression (RegEx) to which the input must match.">
                                <b-form-input v-model="attribute.config.regex" readonly></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4">
                            <b-form-group label="Unique" label-align-sm="right" label-cols-sm="3" description="This indicates whether each input must be unique.">
                                <b-form-checkbox v-model="attribute.config.unique" switch disabled></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6" xxl="4" v-if="attribute.config.case_sensitive !== null">
                            <b-form-group label="Case Sensitive" label-align-sm="right" label-cols-sm="3" description="This indicates whether the input is case sensitive.">
                                <b-form-checkbox v-model="attribute.config.case_sensitive" switch disabled></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>
    </b-card-body>
    <b-card-body v-if="attribute">
        <b-row>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="attribute.created_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4">
                <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="attribute.created_by" readonly></b-form-input>
                        <b-badge v-if="attribute.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${attribute.created_by}`" variant="outline-primary" :disabled="attribute.created_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="attribute.updated_at">
                <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="attribute.updated_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="attribute.updated_by">
                <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                    <b-input-group>
                        <b-form-input v-model="attribute.updated_by" readonly></b-form-input>
                        <b-badge v-if="attribute.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                        <b-input-group-append>
                            <b-button :to="`/accounts/${attribute.updated_by}`" variant="outline-primary" :disabled="attribute.updated_by === 'SYSTEM'">Open</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col xl="6" xxl="4" v-if="attribute.expires_at">
                <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                    <b-form-input :value="attribute.expires_at.toLocaleString()" readonly></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="success" v-on:click="saveAttribute()" :disabled="!canEdit() || !validAttribute()">Save</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="$emit('load', 'attribute')">Refresh</b-button>
        <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-attribute', attribute)" :disabled="!canEdit()">Delete</b-button>
    </b-card-body>
    <b-card-footer v-if="attribute?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ attribute.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
 const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'string', text: 'String' },
    { value: 'number', text: 'Number' },
    { value: 'boolean', text: 'Boolean' },
    { value: 'url', text: 'URL' },
    { value: 'json', text: 'JSON' },
    { value: 'string:oidc1:name', text: 'Name' },
    { value: 'string:oidc1:given_name', text: 'Given Name' },
    { value: 'string:oidc1:family_name', text: 'Family Name' },
    { value: 'string:oidc1:middle_name', text: 'Middle Name' },
    { value: 'string:oidc1:nickname', text: 'Nickname' },
    { value: 'string:oidc1:preferred_username', text: 'Preferred Username' },
    { value: 'url:oidc1:profile', text: 'Profile' },
    { value: 'url:oidc1:picture', text: 'Picture' },
    { value: 'url:oidc1:website', text: 'Website' },
    { value: 'string:oidc1:email', text: 'Email' },
    { value: 'string:oidc1:gender', text: 'Gender' },
    { value: 'string:oidc1:birthdate', text: 'Birthdate' },
    { value: 'string:oidc1:zoneinfo', text: 'Time Zone' },
    { value: 'string:oidc1:locale', text: 'Locale' },
    { value: 'string:oidc1:phone_number', text: 'Phone Number' },
    { value: 'string:oidc1:address:formatted', text: 'Formatted Address' },
    { value: 'string:oidc1:address:street_address', text: 'Street Address' },
    { value: 'string:oidc1:address:locality', text: 'Locality' },
    { value: 'string:oidc1:address:region', text: 'Region' },
    { value: 'string:oidc1:address:postal_code', text: 'Postal Code' },
    { value: 'string:oidc1:address:country', text: 'Country' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Attribute',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'loading', 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        attribute: Object
    },

    /**
     * DATA
     */
    data() {
        return {
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * METHODS
     */
    methods: {

        /**
         * ATTRIBUTE
         */
        async saveAttribute() {
            this.$emit('loading', 'Saving');
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateAttribute($input: UpdateAttributeInput!) {
                                updateAttribute(input: $input) {
                                    id
                                    label
                                    value
                                    score
                                    status
                                    subtype
                                    config {
                                        require_validation_for_enablement
                                        case_sensitive
                                        internal
                                        unique
                                        regex
                                        expires_in
                                        restricted
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.attribute.id}",
                                "subtype": "${this.attribute.subtype}",
                                "value": "${this.attribute.value}",
                                "score": ${this.attribute.score},
                                "label": "${this.attribute.label}",
                                "status": "${this.attribute.status}",
                                "config": {
                                    "require_validation_for_enablement": ${this.attribute.config.require_validation_for_enablement},
                                    "internal": ${this.attribute.config.internal},
                                    "restricted": ${this.attribute.config.restricted}
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const attribute = (await response.json()).data.updateAttribute;
                    this.$emit('load', 'attribute');
                    this.$emit('alert', 'Your attribute has been updated', 'Attribute', 'success', 5000);
                    this.$emit('load', 'attributes');
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save attribute.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save attribute.', 'Attribute', 'danger');
            }
            this.$emit('loading', undefined);
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.attribute?.status);
        },
        
        validAttribute() {
            if (!this.validField('label')) return false;
            if (!this.validField('value')) return false;
            if (!this.validField('score')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.attribute.label;
                case 'value':
                    return !!this.attribute.label;
                case 'score':
                    return this.attribute.score >= 0;
                default:
                    return false;
            }
        }
    }
}
</script>